import { Grid, Stack, Box, Typography, Container } from "@mui/material";
import Logo from "../assets/images/Fertilizer_FAQ.webp";
import ImageBox from "../components/imagebox/ImageBox";

const faq = [
  {
    q: "How exactly do I fertilize my lawn?",
    a: [
      "There are actually 3 parts to this answer. First, choose a quality fertilizer, then apply the right amount (rate) and apply at the right time of the season. Quality nitrogen fertilizers should contain controlled-release nitrogen (see next question for more on this). The amount or rate of nitrogen to apply is about one pound of actual nitrogen per 1,000 square feet of lawn area (see question below for more on this). Finally, some suggested times in the season for northern Illinois would be early may, early September, and late fall (about Halloween).",
    ],
  },
  {
    q: "What is the best fertilizer to use?",
    a: [
      "There is no one perfect or absolute best fertilizer to purchase for your lawn, but there are many good ones available. Nitrogen is the most important nutrient; percent nitrogen is expressed as the first number in the series of three on the fertilizer bag. What kind of nitrogen in the product is the key information, as there are fast-release and controlled-release nitrogen sources. To find the answer, look in the guaranteed analysis section on the back of the bag. Key terms to look for include controlled-release, slow-release, slowly-available, or water-insoluble nitrogen. Some specific types that may be listed include ureaform, sulfur-coated urea, polymer-coated urea, and IBDU (isobutylidenediurea). Several organic nitrogen sources are controlled-release.",
      "Most quality lawn fertilizers offer a balance of fast and controlled release sources to offer a fertilizer that will provide some quick color (without a big surge of growth) and some long-lasting nitrogen.",
    ],
  },
  {
    q: "I don’t want to burn my grass with high percent nitrogen fertilizer, so can I just use a 10-10-10 garen fertilizer?",
    a: [
      "The high percentage of nitrogen by itself is not a problem, assuming the amount put down on the lawn is adjusted accordingly. The higher the percent nitrogen, the less product is needed on the lawn to supply the one pound per 1,000 square feet rate suggested. In addition, if the material is controlled-release, the risk of burning the lawn is low even though the percentage nitrogen in the product may be high.",
      "Balanced fertilizers, such as a 10-10-10, have a N:P:K ratio of 1:1:1. The reason these are not suggested for repeated use on lawns is the amount of phosphorus applied, when supplying the one pound of nitrogen per 1,000 square feet, becomes quite high. Excess phosphorus may lead to potential runoff problems and more weeds in the lawn. Also, many of the all-purpose balanced fertilizers have only fast-release nitrogen as the nitrogen component, so the risk of burn may be higher.",
    ],
  },
  {
    q: "How do I know how much fertilizer to apply?",
    a: [
      "As mentioned earlier, the suggested rate per application is about one pound of actual nitrogen per 1,000 square feet of lawn. If fertilizers were 100 percent nitrogen, which they are not, it would take a pound of fertilizer to apply this rate. Divide 100 by the percent nitrogen in the bag (first number expressed a whole number) to figure how much is needed per 1,000 square feet. For example, a fertilizer with 20 percent nitrogen would require 100/20 or 5 pounds of fertilizer product per every 1,000 square feet of lawn.",
      "The next figure needed is the total area or square footage of the lawn. Take your total lot size and subtract everything not in lawn to determine this figure. An acre is 43,560 square feet, if you know your lot expressed in a portion of an acre. Then subtract the square footage of the house, driveway, gardens, patio, etc. The other way to figure is just to measure dimensions of the lawn areas and calculate as square feet.",
      "Once you arrive at the square footage in lawn, divide this by 1,000. Then multiply by the pounds of fertilizer needed per 1,000 square feet figured above. This gives you the approximate pounds of fertilizer needed to spread on your lawn.",
      "Using the previous example of a 20 percent nitrogen fertilizer, let’s walk through this. Dividing 100 by 20 gives 5, meaning 5 pounds of fertilizer is needed to supply 1 pound of actual nitrogen for every 1,000 square feet. If the lawn measures out to 12,000 square feet, dividing 12,000 by 1000 gives 12. Now take 5 times 12, which equals 60. This means it would take 60 pounds of fertilizer, spread over the 12,000 square foot lawn, to supply a rate of 1 pound of actual nitrogen per 1,000 square feet.",
      "Finally, most fertilizer bags give a spreader setting guide, which is usually calibrated to supply the 1 pound per 1,000 square feet rate, to help homeowners apply the right amount. The product label may tell you how much area the product will cover.",
    ],
  },
  {
    q: "What about weed control and fertilizer products?",
    a: [
      "Weed and feed products are popular in that they provide fertilizer and weed control in one product. Early fall is a good time for controlling broadleaf weeds, such as dandelions, and fertilizing. Read and follow all label directions.",
      "Another fertilizer and herbicide product is the fertilizer and crabgrass control products sold in spring. These contain fertilizer and a preemergence herbicide for crabgrass control. Once again, read and follow all label directions.",
    ],
  },
  {
    q: "When should soils be limed?",
    a: [
      "Lime is added to soil when a soil test determines that the soil pH is too acid. Lime reacts with water and releases calcium that neutralizes the acidity of the soil, thus raising the pH of the soil.",
      "How do I take a soil sample correctly?",
      "Using a trowel or shovel, dig a V-shaped hole in the soil four to six inches deep. Remove the entire soil wedge. Collect similar “wedges” from several spots in the area you wish to plant. Mix all the individual “wedges” together. One pint of this mixed sample is sufficient for testing purposes. The sample should be free of all plant debris and rocks. It is also very important for the soil to be completely dry.",
      "What will a soil pH test tell me?",
      "A soil pH test will tell you how acidic or alkaline your soil is. It will not tell you the fertilizers you need, what diseases are present in the sample, or any other reasons why plants won’t grow in an area. Homeowners who want a more complete soil test can send samples to their local AG extension office. This test measures and makes recommendations for pH, phosphorus, potassium, magnesium and calcium. Nitrogen is not measured.",
    ],
  },
  {
    q: "Is it safe to apply pelletized lime and plant grass seed at the same time?",
    a: [
      "No it is not.  Pelletized Lime can strip the germination coating from the grass seed.  Wait to apply the Pelletized Lime until the lawn is established.",
    ],
  },
  {
    q: "What is the best fertilizer to use when planting new grass?",
    a: [
      "A low nitrogen fertilizer like Greenskeeper’s Secret 6-24-24.  Newly planted grass seed is too fragile for high nitrogen fertilizers.",
    ],
  },
  {
    q: "What is the best type of fertilizer to use to maintain my lawn throughout the hot summer?",
    a: [
      "Greenskeeper’s Secret 12-12-12 is a good, well balanced all around fertilizer.  Applying too much nitrogen during the hot summer months can burn your lawn.",
    ],
  },
  {
    q: "What is the application rate for grass seed?",
    a: [
      "For a fine bladed turf type fescue, apply 8-10 pounds per 1,000 square feet for new lawns or 3-4 pounds per 1,000 square feet to over seed your existing lawn.",
    ],
  },
  {
    q: "How soon can I use a weed and feed type fertilizer after planting new grass seed?",
    a: [
      "For best results, wait until the new grass seed is established and has been mowed 2-3 times.",
    ],
  },
  {
    q: "When is the best time to put down Greenskeeper’s Secret Crabgrass Preventer?",
    a: [
      "It is best to apply the Greenskeeper’s Secret Crabgrass Preventer before the crabgrass germinates; which would be late February thru early April.",
    ],
  },
  {
    q: "How soon after spreading Greenskeeper’s Secret Crabgrass Preventer can grass seed be applied?",
    a: [
      "Oakley Professional Products recommends waiting a minimum of four months between spreading Greenskeeper’s Secret Crabgrass Preventer and planting grass seed.  The chemicals used to prevent the crabgrass from germinating will also prevent the grass seed from germinating.",
    ],
  },
  {
    q: "What is the best type of fertilizer to use in the spring?",
    a: [
      "Applying a high nitrogen fertilizer like Greenskeeper’s Secret Jumpstart 32-3-10 or Greenskeeper’s Secret 20-10-10 will get your grass green and growing in no time.",
    ],
  },
  {
    q: "What is the best type of fertilizer to apply in the fall?",
    a: [
      "Oakley Professional Products recommends applying a lower nitrogen fertilizer.  Fertilizers with higher phosphate and potash rates will help strengthen the root system throughout the fall and winter months.",
    ],
  },
];

const FertilizerFAQ = () => {
  return (
    <Grid container>
      <Grid item xs={12} mt={{ xs: 3, sm: 5 }}>
        <Container>
          <Stack
            sx={{
              width: "100%",
            }}
          >
            <Typography align="center" variant="h3">
              Fertilizer FAQs
            </Typography>

            <Box justifyContent="center" display="flex" mb={3}>
              <ImageBox src={Logo} sx={{ width: { xs: "75%", sm: "50%" } }} />
            </Box>

            {faq.map((qa) => {
              return (
                <>
                  <Typography variant="h6" mt={2}>
                    {qa.q}
                  </Typography>

                  <Stack spacing={1}>
                    {qa.a.map((a) => {
                      return <Typography variant="body1">{a}</Typography>;
                    })}
                  </Stack>
                </>
              );
            })}
          </Stack>
        </Container>
      </Grid>
    </Grid>
  );
};

export default FertilizerFAQ;
